<template>
  <div>
    <div v-if="$route.name == 'brokerlist' || $route.name == 'brokerranking'" class="columns is-widescreen">
      <div class="column is-2-fullhd"></div>
      <div class="column">
        <div class="columns padding-bottom-2rem" v-if="ranking">
          <div class="column is-1"></div>
          <div class="column content">
            <h1 class="title has-fancy-underline has-text-centered padding-bottom-1rem" id="brokerList"
              v-if="$route.name == 'brokerranking'">
              <span>{{ title }}</span>
            </h1>
            <div class="has-text-centered">
              <picture>
                <source type="image/webp" :srcset="ranking.coverImageSrcWebp" />
                <img :src="ranking.coverImageSrcJpg" :alt="ranking.coverImageAlt" />
              </picture>
            </div>
            <div class="columns is-gapless" v-if="ranking.coverImageSrcJpg && ranking.coverImageAuthorLink">
              <div class="column"></div>
              <div class="column is-2" v-if="ranking.coverImageAuthor">
                <small>Image by
                  <a :href="ranking.coverImageAuthorLink" rel="noreferrer
          noopener nofollow" target="_blank">{{ ranking.coverImageAuthor }}</a>
                </small>
              </div>
            </div>
            <component v-if="ranking" :is="rankingTextLoader" :key="$route.params.slug"></component>
          </div>
          <div class="column is-1"></div>
        </div>
        <div v-if="$route.name == 'brokerlist'">
          <h1 class="
            title
            has-fancy-underline has-text-centered
            padding-bottom-1rem
          " id="brokerList">
            <span>{{ title }}</span>
          </h1>
          <h3 class="subtitle has-text-centered margin-bottom-1rem">
            <span>Widzisz {{ filteredBrokers.length }} z
              {{ brokers.length }} porównywanych brokerów.</span>
            <br />
            <small>Ostatnia aktualizacja {{ informationDate }}</small>
          </h3>
        </div>

        <div class="columns is-fullhd">
          <div class="column is-1"></div>
          <div class="column">
            <div class="padding-bottom-1-5rem box">
              <h3 class="heading-bigger has-text-centered">
                <div class="cursor-click" @click="showFilters = !showFilters">
                  <button class="button is-outlined is-fullwidth">
                    <span class="icon has-text-info">
                      <lord-icon animation="hover" target="button" params="200" palette="#0291e0;#000000"
                        src="/lordicon/201-settings-sliders-morph-outline-edited.json"></lord-icon>
                    </span>
                    <span>Filtry i sortowanie</span>
                    <span class="icon">
                      <lord-icon animation="hover" target="button" params="200" palette="#0291e0;#000000" :src="showFilters
                        ? '/lordicon/34-arrow-up-outline-edited.json'
                        : '/lordicon/33-arrow-down-outline-edited.json'
                        "></lord-icon>
                    </span>
                  </button>
                </div>
              </h3>
              <div v-show="showFilters">
                <div class="columns is-multiline margin-top-1rem">
                  <div class="column is-half">
                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Sortowanie</label>
                      <div class="select is-fullwidth">
                        <select v-model="sorting">
                          <option v-for="option in sortingOptions" :key="option" :value="option">{{
                            sortingLabels[option]
                          }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="control padding-bottom-1rem">
                      <label class="block-label">kalkulatorgieldowy.pl</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterKalkulatorgieldowy">
                          <option>Dowolnie</option>
                          <option>Tak</option>
                          <option>Nie</option>
                        </select>
                      </div>
                    </div>

                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Instrumenty</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterInstrument">
                          <option>Dowolne</option>
                          <option>Forex</option>
                          <option>Akcje</option>
                          <option>Indeksy</option>
                          <option>Surowce</option>
                          <option>Kryptowaluty</option>
                          <option>ETF</option>
                        </select>
                      </div>
                    </div>
                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Metoda wpłaty</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterFundingMethod">
                          <option>Dowolna</option>
                          <option v-for="fundingMethod in allFundingMethods" :key="fundingMethod">
                            {{ fundingMethod }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Akcje ułamkowe</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterFractionalShares">
                          <option>Dowolnie</option>
                          <option>Tak</option>
                          <option>Nie</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="column is-half">
                    <div class="control padding-bottom-1rem" @mouseover="mouseOver.platform = true"
                      @mouseleave="mouseOver.platform = false">
                      <label class="block-label">
                        Platforma
                        <font-awesome-icon v-show="mouseOver.platform || isMobile()"
                          class="clickable-icon has-text-link animated tada" icon="question"
                          @click="showInfoModalWith('platform')"></font-awesome-icon>
                      </label>
                      <div class="select is-fullwidth">
                        <select v-model="filterPlatform">
                          <option v-for="option in allPlatforms" :key="option">
                            {{ option }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Waluta konta</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterAccountCurrency">
                          <option>Dowolna</option>
                          <option v-for="currency in allAccountCurrencies" :key="currency">
                            {{ currency }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="control padding-bottom-1rem" @mouseover="mouseOver.ecn = true"
                      @mouseleave="mouseOver.ecn = false">
                      <label class="block-label">
                        ECN
                        <font-awesome-icon v-show="mouseOver.ecn || isMobile()"
                          class="clickable-icon has-text-link animated tada" icon="question"
                          @click="showInfoModalWith('ecn')"></font-awesome-icon>
                      </label>

                      <div class="select is-fullwidth">
                        <select v-model="filterEcn">
                          <option>Dowolnie</option>
                          <option>Tak</option>
                          <option>Nie</option>
                        </select>
                      </div>
                    </div>
                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Kraj regulacji</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterCountryOfRegulation">
                          <option v-for="country in allCountryOfRegulation" :key="country">
                            {{ country }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="control padding-bottom-1rem">
                      <label class="block-label">Oprocentowanie wolnych środków</label>
                      <div class="select is-fullwidth">
                        <select v-model="filterInterestRate">
                          <option>Dowolnie</option>
                          <option>Tak</option>
                          <option>Nie</option>
                        </select>
                      </div>
                    </div>


                  </div>
                </div>

                <div class="field">
                  <span>Brakuje filtra?&nbsp;</span><a href="https://forms.gle/8V6T7Di7nUP1zK4z6" target="_blank"
                    rel="noopener noreferrer">Daj
                    nam znać!</a>
                </div>

                <hr />
                <div class="field margin-top-2rem padding-bottom-1rem">
                  <h3 class="heading-bigger has-text-centered has-text-weight-semibold">
                    Wybierz jakie chcesz widzieć informacje
                  </h3>
                  <div class="control padding-bottom-1rem is-hidden">
                    <label class="block-label">Tryb przeglądania</label>
                    <div class="select is-fullwidth">
                      <select v-model="viewMode">
                        <option value="custom">Własny</option>
                        <option value="beginner">Początkujący</option>
                        <option value="intermediate">Średniozaawansowany</option>
                        <option value="advanced">Zaawansowany</option>
                        <option value="full">Wszystkie pola</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-multiline">
                  <div class="buttons">
                    <button class="button is-outlined is-small heading" @click="toggleField('description')"
                      :class="{ 'is-info': fields.description }">
                      opis
                    </button>

                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.rate }"
                      @click="toggleField('rate')">
                      ocena
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.minInstallment }" @click="toggleField('minInstallment')">
                      Wpłata minimalna
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.popularity }"
                      @click="toggleField('popularity')">
                      Popularność
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.currencies }"
                      @click="toggleField('currencies')">
                      Waluta konta
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.countryOfOrigin }" @click="toggleField('countryOfOrigin')">
                      Kraj pochodzenia
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.countryOfRegulation }" @click="toggleField('countryOfRegulation')">
                      Kraj regulacji
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.investmentProtection }" @click="toggleField('investmentProtection')">
                      Ochrona przed ujemnym saldem
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.platforms }"
                      @click="toggleField('platforms')">
                      Platforma
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.instruments }"
                      @click="toggleField('instruments')">
                      Instrumenty
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.fundingMethods }" @click="toggleField('fundingMethods')">
                      Metody wpłaty
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.spread }"
                      @click="toggleField('spread')">
                      Spread
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.kalkulatorgieldowy }" @click="toggleField('kalkulatorgieldowy')">
                      kalkulatorgieldowy.pl
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.commission }"
                      @click="toggleField('commission')">
                      Prowizje i opłaty
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.leverage }"
                      @click="toggleField('leverage')">
                      Dźwignia
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.ecn }"
                      @click="toggleField('ecn')">
                      ECN
                    </button>
                    <button class="button is-outlined is-small is-uppercase" :class="{ 'is-info': fields.interestRate }"
                      @click="toggleField('interestRate')">
                      Oprocentowanie wolnych środków
                    </button>
                    <button class="button is-outlined is-small is-uppercase"
                      :class="{ 'is-info': fields.fractionalShares }" @click="toggleField('fractionalShares')">
                      Akcje ułamkowe
                    </button>
                  </div>
                </div>
              </div>
              <div v-show="showFilters" class="
                  padding-top-2rem
                  has-text-centered has-text-info
                  cursor-click
                " @click="showFilters = false">
                zamknij filtry i sortowanie
              </div>
            </div>


            <div class="box" v-if="$route.name == 'brokerlist'">
              <span class="class title is-size-5">Szybkie filtry</span>
              <div class="buttons margin-top-1rem">
                <button class="button" @click="setQuickFilterLowestSpread">
                  Najniższy spread
                </button>
                <button class="button" @click="setQuickFilterOnlineFunding">
                  Przelewy online
                </button>
                <button class="button" @click="setQuickFilterBrokersWithETF">
                  Brokerzy z ETF-ami
                </button>
                <button class="button" @click="setQuickFilterBrokersWithFractionalShares">
                  Akcje ułamkowe
                </button>
              </div>
            </div>

            <div class="has-text-centered" v-if="filteredBrokers.length == 0">
              <lord-icon animation="auto" class="sad-icon" params="90" palette="#121331;#3273dc" speed="0.5"
                src="/lordicon/263-emoji-sad-outline.json"></lord-icon>
              <p>
                Niestety nic dla Ciebie nie znaleźliśmy... spróbuj zmienić
                filtry!
              </p>
            </div>
            <div class="field is-grouped is-grouped-multiline has-text-centered">
              <div class="control" v-for="(value, filterName) in notEmptyFilters" :key="filterName">
                <div class="tags has-addons">
                  <span v-if="filterName == 'ecn'" class="tag is-info is-light is-medium is-rounded">ECN:
                    {{
                      value
                    }}</span>
                  <span v-if="filterName == 'fractionalShares'" class="tag is-info is-light is-medium is-rounded">Akcje
                    ułamkowe:
                    {{
                      value
                    }}</span>
                  <span v-if="filterName == 'sorting'" class="tag is-info is-light is-medium is-rounded">Sortowanie: {{
                    value }}</span>
                  <span v-if="filterName == 'interestRate'"
                    class="tag is-info is-light is-medium is-rounded">Oprocentowanie wolnych środków: {{
                      value }}</span>
                  <span v-if="filterName == 'kalkulatorgieldowy'"
                    class="tag is-info is-light is-medium is-rounded">kalulatorgieldowy.pl: {{
                      value }}</span>
                  <span v-if="!(
                    filterName == 'ecn' ||
                    filterName == 'kalkulatorgieldowy' ||
                    filterName == 'fractionalShares' ||
                    filterName == 'sorting' ||
                    filterName == 'interestRate'
                  )
                  " class="tag is-info is-light is-medium is-rounded">{{ value }}</span>
                  <a class="tag is-delete is-info is-light is-medium is-rounded" @click="resetFilter(filterName)"></a>
                </div>
              </div>
            </div>
            <broker-list-element v-for="(broker, index) in filteredBrokers" :broker="broker" :key="broker.name"
              :index="index" :addToCompare="addToCompare" :removeFromCompare="removeFromCompare"
              :fields="fields"></broker-list-element>
          </div>
          <div class="column is-1"></div>
        </div>
        <div>
          <div class="columns">
            <div class="column content is-full-width has-text-centered">
              <h3 class="has-fancy-underline">
                Zobacz inne rankingi JakiBroker.pl
              </h3>
            </div>
          </div>
          <div class="columns">
            <RankingWidget class="column is-half" :ranking="suggestedRanking1"></RankingWidget>
            <RankingWidget class="column is-half" :ranking="suggestedRanking2"></RankingWidget>
          </div>
        </div>
        <div class="compare-box box" v-if="compare.length">
          <div class="columns is-gapless margin-bottom-0" @click="minimizeCompareBox = !minimizeCompareBox">
            <div class="column has-text-centered">
              <span class="icon">
                <lord-icon animation="hover" target="button" params="200" palette="#0291e0;#000000" :src="minimizeCompareBox
                  ? '/lordicon/34-arrow-up-outline-edited.json'
                  : '/lordicon/33-arrow-down-outline-edited.json'
                  "></lord-icon>
              </span>
            </div>
          </div>

          <h3 class="subtitle" v-if="!minimizeCompareBox">Aktualne porównanie</h3>

          <template v-if="!minimizeCompareBox">
            <div class="control padding-bottom-1rem" v-for="brokerToCompare in compare" :key="brokerToCompare">
              <div class="tags has-addons">
                <span class="tag is-info is-light is-medium is-rounded">{{ loadBroker(brokerToCompare).name }}</span>
                <a class="tag is-delete is-info is-light is-medium is-rounded"
                  @click="removeFromCompare(brokerToCompare)"></a>
              </div>
            </div>
          </template>
          <button class="button is-info is-fullwidth" :disabled="compare.length < 2" title="Porównaj"
            @click="openComparePage">
            <span>Porównaj ({{ compare.length }})</span>
            <span class="icon">
              <font-awesome-icon class="
                        clickable-icon
                        animated tada
                      " icon="arrow-circle-right"></font-awesome-icon>
            </span>
          </button>
        </div>
      </div>

      <div class="column is-1 is-2-fullhd"></div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import BrokerListElement from "./BrokerListElement";
import RankingWidget from "./RankingWidget.vue";
import database from "@/database.json";
import { sortingOptions, sortingLabels } from "./BrokerList.constants";

export default {
  name: "BrokerList",
  components: {
    BrokerListElement,
    RankingWidget,
  },
  data() {
    return {
      minimizeCompareBox: this.isMobile(),
      sortingOptions,
      sortingLabels,
      brokers: database,
      showFields: false,
      showFilters: false,
      viewMode: "beginner",
      title: "Ranking najlepszych brokerów",
      windowWidth: false,
      ranking: null,
      mouseOver: {
        ecn: false,
        platform: false,
        kalkulatorgieldowy: false,
        fractionalShares: false,
      },
      fieldModes: {
        beginner: {
          description: true,
          rate: true,
          minInstallment: true,
          currencies: true,
          countryOfOrigin: false,
          investmentProtection: true,
          platforms: false,
          countryOfRegulation: false,
          kalkulatorgieldowy: true,
          instruments: true,
          spread: false,
          commission: false,
          fundingMethods: true,
          leverage: false,
          ecn: false,
          interestRate: false,
          fractionalShares: true,
          popularity: true,
        },
        intermediate: {
          description: true,
          rate: true,
          minInstallment: true,
          currencies: true,
          countryOfOrigin: false,
          investmentProtection: true,
          platforms: false,
          countryOfRegulation: false,
          kalkulatorgieldowy: true,
          instruments: true,
          spread: true,
          commission: true,
          fundingMethods: true,
          leverage: false,
          ecn: false,
          interestRate: true,
          fractionalShares: true,
          popularity: true,
        },
        advanced: {
          description: false,
          rate: false,
          minInstallment: false,
          currencies: true,
          countryOfOrigin: false,
          investmentProtection: true,
          platforms: true,
          countryOfRegulation: true,
          instruments: true,
          kalkulatorgieldowy: true,
          spread: true,
          commission: true,
          fundingMethods: true,
          leverage: true,
          ecn: true,
          interestRate: true,
          fractionalShares: true,
          popularity: true,
        },
        full: {
          description: true,
          rate: true,
          minInstallment: true,
          currencies: true,
          countryOfOrigin: true,
          investmentProtection: true,
          platforms: true,
          countryOfRegulation: true,
          instruments: true,
          kalkulatorgieldowy: true,
          spread: true,
          commission: true,
          fundingMethods: true,
          leverage: true,
          ecn: true,
          interestRate: true,
          fractionalShares: true
        },
      },
      fields: {
        description: true,
        rate: true,
        minInstallment: true,
        currencies: true,
        countryOfOrigin: false,
        investmentProtection: false,
        platforms: false,
        countryOfRegulation: false,
        instruments: false,
        kalkulatorgieldowy: true,
        spread: false,
        commission: false,
        fundingMethods: false,
        leverage: false,
        ecn: false,
        interestRate: true,
        fractionalShares: false,
        popularity: true,
      },
    };
  },
  methods: {
    addToCompare(slug) {
      this.$gtag.event("addToCompare", { slug });
      this.$store.commit("addToCompareBox", slug);
    },
    openComparePage() {
      let slugs = [...this.compare];
      slugs.sort();
      this.$gtag.event("compareOpenPage", slugs);
      this.$router.push({
        name: "compare",
        params: { slugs: slugs.join("-vs-") }
      });
    },
    toggleField(field) {
      this.fields[field] = !this.fields[field];
      this.viewMode = "custom";
    },
    resetFilter(key) {
      if (key == "sorting") {
        this.sorting = sortingOptions.suggested;
      }
      else {
        this.$store.commit("resetFilter", key);
      }
    },
    removeFromCompare(slug) {
      this.$store.commit("removeFromCompareBox", slug);
    },
    setQuickFilterLowestSpread() {
      this.sorting = this.sortingOptions.lowestSpread
      this.filterInstrument = "Forex";
      Vue.set(this.fields, "commission", true);
      this.fields.spread = true;

      this.$gtag.event("setQuickFilterLowestSpread", 'lowestSpread');
    },
    setQuickFilterOnlineFunding() {
      this.filterFundingMethod = "Szybkie przelewy online";
      this.fieldsFundingMethod = true;
      this.$gtag.event("setQuickFilterOnlineFunding", 'onlineFunding');
    },
    setQuickFilterBrokersWithETF() {
      this.filterInstrument = "ETF";
      this.sorting = this.sortingOptions.suggested
      this.$gtag.event("setQuickFilterBrokersWithETF", 'brokersWithETF');
    },
    setQuickFilterLowestMinimalInstallment() {
      this.sorting = this.sortingOptions.lowestMinimumInstallment
      this.$gtag.event("setQuickFilterLowestMinimalInstallment", 'lowestMinimumInstallment');
    },
    setQuickFilterBrokersWithCommodities() {
      this.filterInstrument = "Surowce";
      this.sorting = this.sortingOptions.suggested
      this.$gtag.event("setQuickFilterBrokersWithCommodities", 'brokersWithCommodities');
    },
    setQuickFilterBrokersWithFractionalShares() {
      this.filterInstrument = "Akcje";
      this.sorting = this.sortingOptions.suggested
      this.$gtag.event("setQuickFilterBrokersWithFractionalShares", 'brokersWithFractionalShares');
      this.$store.commit("setFractionalShares", "Tak");
      this.field.fractionalShares = true;
    },
    setQuickFilterBrokersWithInterestRate() {
      this.sorting = this.sortingOptions.biggestInterestRate
      this.fields.interestRate = true;
      this.$store.commit("setInterestRate", "Tak");
      this.$gtag.event("setQuickFilterBrokersWithInterestRate", 'brokersWithInterestRate');
    },
    setQuickFilterMostPopularBrokers() {
      this.sorting = this.sortingOptions.popularity
      this.fields.popularity = true;
      this.$gtag.event("setQuickFilterMostPopularBrokers", 'mostPopularBrokers');
    },
    setState() {
      const currentMode = this.$store.state.viewMode;
      this.viewMode = currentMode;
      for (let key in this.fieldModes[currentMode]) {
        Vue.set(this.fields, key, this.fieldModes[currentMode][key]);
      }
      if (this.$route.name == "brokerranking") {
        this.$store.commit("resetAllFilters");
        this.slug = this.$route.params.slug;
        this.ranking = this.$store.state.rankings.find(
          (item) => item.slug == this.$route.params.slug
        );

        this.title = this.ranking.title;
        this.$store.commit("setCurrentPageTitle", this.title);
        if (this.slug == "brokerzy-z-najnizszym-spreadem") {
          this.setQuickFilterLowestSpread();
        }
        if (this.slug == "brokerzy-ecn") {
          this.filterEcn = "Tak";
          this.fields.ecn = true;
        }
        if (this.slug == "brokerzy-z-przelewami-online") {
          this.setQuickFilterOnlineFunding();
        }
        if (this.slug == "brokerzy-z-dostepem-do-akcji") {
          this.filterInstrument = "Akcje";
          this.sorting = this.sortingOptions.suggested
        }
        if (this.slug == "brokerzy-z-kryptowalutami") {
          this.filterInstrument = "Kryptowaluty";
          this.sorting = this.sortingOptions.suggested

        }
        if (this.slug == "brokerzy-forex") {
          this.filterInstrument = "Forex";
          this.sorting = this.sortingOptions.suggested
        }
        if (this.slug == "brokerzy-etf") {
          this.setQuickFilterBrokersWithETF();
        }
        if (this.slug == "brokerzy-z-surowcami") {
          this.setQuickFilterBrokersWithCommodities();
        }
        if (this.slug == "brokerzy-z-akcjami-ulamkowymi") {
          this.setQuickFilterBrokersWithFractionalShares();
        }
        if (this.slug == "brokerzy-z-oprocentowaniem-wolnych-srodkow") {
          this.setQuickFilterBrokersWithInterestRate();
        }
        if (this.slug == "najpopularniejsi-brokerzy") {
          this.setQuickFilterMostPopularBrokers();
        }
      } else {
        this.$store.commit(
          "setCurrentPageTitle",
          "Ranking najlepszych brokerów"
        );
      }
    },
  },
  watch: {
    viewMode(newValue) {
      this.$store.commit("setViewMode", newValue);
      if (newValue == "custom") {
        this.showFields = true;
      }
      for (let key in this.fieldModes[newValue]) {
        Vue.set(this.fields, key, this.fieldModes[newValue][key]);
      }
    },
    sorting(newValue) {
      this.$store.commit("setSorting", newValue);
      if (newValue == sortingOptions.lowestSpread) {
        Vue.set(this.fields, "spread", true);
      } else if (newValue == sortingOptions.lowestMinimumInstallment) {
        Vue.set(this.fields, "minInstallment", true);
      } else if (newValue == sortingOptions.highestLeverage) {
        Vue.set(this.fields, "leverage", true);
      } else if (newValue == sortingOptions.biggestInterestRate) {
        Vue.set(this.fields, "interestRate", true);
      }
    },
    $route() {
      this.setState();
    },
  },
  mounted() {
    this.setState();
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    compare() {
      return this.$store.getters.getCompareBox;
    },
    suggestedRanking1() {
      return this.$store.state.rankings[1];
    },
    suggestedRanking2() {
      return this.$store.state.rankings[3];
    },
    informationDate() {
      let date = new Date();
      date.setHours(date.getHours() - 12);
      return date.toLocaleDateString("pl-PL");
    },
    filteredBrokers() {
      this.filterFractionalShares
      return this.$store.getters.filteredBrokers;
    },
    rankingTextLoader() {
      // it's here just to invoke reactivity
      this.$route.params.slug;
      return () => import(`@/rankings/${this.$route.params.slug}.md`);
    },
    questions() {
      return this.$store.state.questions;
    },
    notEmptyFilters() {
      let filters = {};
      if (this.sorting !== sortingOptions.suggested) {
        filters.sorting = this.sortingLabels[this.sorting];
      }
      for (let key of Object.keys(this.$store.state.filter)) {
        const value = this.$store.state.filter[key];
        if (!!value && !value.toLowerCase().startsWith("dowoln")) {
          filters[key] = this.$store.state.filter[key];
        }
      }
      return filters;
    },
    allAccountCurrencies() {
      return this.$store.getters.allAccountCurrencies;
    },
    allFundingMethods() {
      return this.$store.getters.allFundingMethods;
    },
    allCountryOfRegulation() {
      return this.$store.getters.allCountryOfRegulation;
    },
    allPlatforms() {
      return this.$store.getters.allPlatforms;
    },
    computedIsSmallerThanFullHD() {
      return this.windowWidth < 1408;
    },
    // filter related:
    filterAccountCurrency: {
      get() {
        return this.$store.getters.getFilter.accountCurrency;
      },
      set(value) {
        this.$gtag.event("setAccountCurrency", { value });
        this.$store.commit("setAccountCurrency", value);
      },
    },
    filterCountryOfRegulation: {
      get() {
        return this.$store.getters.getFilter.countryOfRegulation;
      },
      set(value) {
        this.$gtag.event("setCountryOfRegulation", { value });
        this.$store.commit("setCountryOfRegulation", value);
      },
    },
    filterFundingMethod: {
      get() {
        return this.$store.getters.getFilter.fundingMethod;
      },
      set(value) {
        this.$gtag.event("setFundingMethod", { value });
        this.$store.commit("setFundingMethod", value);
      },
    },
    filterFractionalShares: {
      get() {
        return this.$store.getters.getFilter.fractionalShares;
      },
      set(value) {
        this.$gtag.event("setFractionalShares", { value });
        this.$store.commit("setFractionalShares", value);
      },
    },
    filterInterestRate: {
      get() {
        return this.$store.getters.getFilter.interestRate;
      },
      set(value) {
        this.$gtag.event("setInterestRate", { value });
        this.$store.commit("setInterestRate", value);
      },
    },
    filterPlatform: {
      get() {
        return this.$store.getters.getFilter.platform;
      },
      set(value) {
        this.$gtag.event("setPlatform", { value });
        this.$store.commit("setPlatform", value);
      },
    },
    filterInstrument: {
      get() {
        return this.$store.getters.getFilter.instrument;
      },
      set(value) {
        this.$gtag.event("setInstrument", { value });
        this.$store.commit("setInstrument", value);
      },
    },
    filterEcn: {
      get() {
        return this.$store.getters.getFilter.ecn;
      },
      set(value) {
        this.$gtag.event("setEcn", { value });
        this.$store.commit("setEcn", value);
      },
    },
    filterKalkulatorgieldowy: {
      get() {
        return this.$store.getters.getFilter.kalkulatorgieldowy;
      },
      set(value) {
        this.$gtag.event("setKalkulatorgieldowy", { value });
        this.$store.commit("setKalkulatorgieldowy", value);
      },
    },
    sorting: {
      get() {
        return this.$store.getters.getSorting;
      },
      set(value) {
        this.$gtag.event("setSorting", { value });
        this.$store.commit("setSorting", value);
      },
    },
    // end of filters
    metaTitle() {
      return this.ranking
        ? this.ranking.title
        : "Ranking brokerów na polskim rynku";
    },
    metaDescription() {
      return this.ranking
        ? this.ranking.description
        : "Zobacz ranking najlepszych brokerów na polskim rynku. Używaj filtrów ECN, spread, prowizje i opłaty, waluta konta, platforma inwestycyjna.";
    },
    metaImageSrc() {
      return this.ranking ? this.ranking.coverImageSrcJpg : "";
    },
    metaUrlContent() {
      if (this.ranking) {
        return `https://jakibroker.pl/ranking/${this.ranking.slug}/`;
      }
      return "https://jakibroker.pl/brokerzy/";
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImageSrc },
        { property: "og:image:url", content: this.metaImageSrc },
        { property: "og:image:width", content: 1200 },
        { property: "og:image:height", content: 628 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "JakiBroker.pl - %s",
    };
  },
};
</script>

<style scoped>
.sad-icon {
  width: 148px;
  height: 148px;
}

.heading-bigger {
  letter-spacing: 1px;
}

.compare-box {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

@media screen and (max-width: 768px) {
  .compare-box {
    bottom: 1rem;
    right: 1rem;
  }
}
</style>
